

$textCol: #FFF;
$bgCol: #666;
$btnCol: #000;
$ff: 'Montserrat', sans-serif;

$screenWidthSmall: 499px;
$screenWidthMedium: 799px;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html, body {
    height: 100%;
}
body {
    background-color: $bgCol;
    background: url('./../img/background.jpg') no-repeat 50% 50% fixed;
    background-size: cover;
    color: $textCol;
    font-family: $ff;
    overflow: hidden;
}
canvas {
    width: 100%;
    height: 100%;
    // image-rendering: -moz-crisp-edges;
    // image-rendering: -webkit-crisp-edges;
    // image-rendering: pixelated;
    // image-rendering: crisp-edges;

}
h1, h2 {
/*     background: linear-gradient($textCol, darken($textCol, 25));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: $textCol;
}
h1 {
    font-size: 4rem;
    margin-bottom: 0.5rem;
}
h2 {
    font-size: 2rem;
}
a {
    color: $textCol;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    width: 10rem;
    vertical-align: middle;
    border-radius: 0.4rem;
    background-color: $btnCol;
    opacity: 0.25;
    transition: 0.4s ease-in-out;
    padding: 0.3rem;
    margin: 0 3rem 1rem 3rem;
}
a:hover, a:active {
    opacity: 0.65;
}

.main, .links {
    display: block;
    position: absolute;
    left: 50%;
    width: 100%;
    min-width: 300px;
    transform: translateX(-50%);
    text-align: center;
}
.main {
    top: 0;
    background-color: rgba($btnCol, 0.15);
    padding: 1rem 0 1.8rem 0;
}
.links {
    bottom: 1rem;
}

@media only screen and (max-width: $screenWidthMedium)
{
    h1 {
        font-size: 3rem;
        margin-bottom: 0.38rem;
    }
    h2 {
        font-size: 1.5rem;
    }   
    .main {
        padding: 0.75rem 0 1.34rem 0;
    }
}
@media only screen and (max-width: $screenWidthSmall)
{
    h1 {
        font-size: 2rem;
        margin-bottom: 0.25rem;
    }
    h2 {
        font-size: 1rem;
    }
    .main {
        padding: 0.75rem 0 1.2rem 0;
    }
}